var ko = require("knockout");
var Browser = require("../Core/BrowserControl");
var Helpers = require("../Core/Helpers");
var BrowserSso = require("../Core/BrowserSso");
var Promise = require("../Core/Promise");
var PromiseHelpers = require("../Core/PromiseHelpers");

var BrowserHelper = Browser.Helper;
var ObjectHelpers = Helpers.Object;


module.exports = function (serverData)
{
    var _this = this;

    
    var _serverData = serverData;

    var _bssoConfig = _serverData.bsso;
    var _postUrl = _serverData.urlPost;
    var _postParams = _serverData.oPostParams;
    

    
    _this.postParams = [];

    _this.forceSubmit = ko.observable(false);
    _this.postUrl = ko.observable();
    

    
    function _submit()
    {
        if (_postParams)
        {
            _this.forceSubmit(true);
        }
        else
        {
            document.location.replace(_this.postUrl());
        }
    }

    (function _initialize()
    {
        _this.postUrl(_postUrl);

        ObjectHelpers.forEach(
            _postParams,
            function (name, value)
            {
                _this.postParams.push(
                    {
                        unsafe_name: BrowserHelper.htmlUnescape(name),
                        unsafe_value: BrowserHelper.htmlUnescape(value)
                    });
            });

        var browserSsoHelper = new BrowserSso(_serverData);

        if (browserSsoHelper.isEnabled())
        {
            
            
            
            var timeoutPromise = new Promise(
                function (resolve)
                {
                    setTimeout(resolve, _bssoConfig.overallTimeoutMs);
                });

            PromiseHelpers.throwUnhandledExceptionOnRejection(
                Promise.race([browserSsoHelper.pullBrowserSsoCookieAsync(), timeoutPromise])
                    .then(
                        function (result)
                        {
                            if (result && result.redirectUrl)
                            {
                                _this.postUrl(result.redirectUrl);
                            }
                        })
                    .then(_submit, _submit));
        }
        else
        {
            
            
            
            
            
            setTimeout(_submit, 0);
        }
    })();
    
};