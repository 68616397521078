var w = window;

var ko = require("knockout");
var KnockoutExtensions = require("../Core/KnockoutExtensions");
var BssoInterruptPageViewModel = require("./BssoInterruptPageViewModel");

KnockoutExtensions.applyExtensions(ko);

ko.utils.registerEventHandler(w, "load",
    function ()
    {
        document.body.appendChild(document.createElement("div")).innerHTML = require("html/BssoInterrupt/BssoInterruptPageHtml.html");
        ko.applyBindings(new BssoInterruptPageViewModel(w.ServerData));
    });